<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">Actual Cost Details</h1>
              <button
                @click="toggleModal"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="container pb-2">
            <h5><b>Activity Name: </b>{{activity_details.name}}</h5>
            <h5><b>Budget Period: </b>{{activity_details.budget_range}}</h5>
            <h5><b>Budget Amount: </b>{{commaFormat(activity_details.budget_amount)}}</h5>
            <div class="table-responsive">
              <table class="table table-bordered table-striped mt-2" v-if="costDetails.length > 0">
                <thead>
                  <tr>
                    <th>Voucher Date</th>
                    <th>Voucher Number</th>
                    <th>Account Name</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cost of costDetails">
                    <td>{{cost.date}}</td>
                    <td>{{cost.voucher_no}}</td>
                    <td>{{cost.head.name}}</td>
                    <td class="text-right">{{commaFormat(cost.debit)}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">Total</td>
                    <td class="text-right">{{totalCost()}}</td>
                  </tr>
                  <tr>
                    <td colspan="3">Balance</td>
                    <td class="text-right">{{getBalance()}}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import figureFormatter              from "@/services/utils/figureFormatter";

const {commaFormat} = figureFormatter();

const props = defineProps({
  activity_details: Object,
  actual_cost_details: Object,
  item: {
    type: Object,
    default: {}
  }
});
const emit = defineEmits(['onCloseModal', 'onBusinessGeneralSave'])

let isOpen = ref(false);
let loading = ref(false);

const costDetails = computed( () => {
  return props.actual_cost_details.details.filter( cost => {
    if(props.item.id !== null && cost.id !== props.item?.id)
      return cost
  })
})

const toggleModal = () => {
  if (isOpen.value) {
    emit("onCloseModal")
  }
  isOpen.value = !isOpen.value
}

const totalCost = () => {
  return commaFormat(props.actual_cost_details.total - (props.item.old_debit ?? 0));
}
const getBalance = () => {
  let balance = props.activity_details.budget_amount - props.actual_cost_details.total;
  balance += props.item.old_debit ?? 0
  return commaFormat(balance)
}
defineExpose({toggleModal});

</script>